import axios from "axios";

const postEventLeads = (
    name,
    phone,
    source,
    source_id,
    pdf_link,
    setLoading,
    setValidationError
) => {
    let data = {
        name: name,
        phone: phone,
        source: source,
        source_id: source_id,
    };
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/postEventLeads`, data)
        .then((response) => {
            if (response && response.status === 200) {
                window.open(pdf_link, "_blank");
            }
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setValidationError("Something Went Wrong.Please Try again");
            console.log(error);
        });
};

export default postEventLeads;
