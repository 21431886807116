import axios from "axios";
import { MISC_URL } from "../../../../Constants/UrlConstants";

function getEventDetailsData(event_id, setEventDetails, setLoading) {
    axios
        .get(
            `${process.env.REACT_APP_MISC_URL}/getEventDetails`,

            {
                params: {
                    id: event_id,
                },
            }
        )
        .then((response) => {
            if(response && response.data && response.data[0]){
                response.data[0]["event_id"] = event_id;
                setEventDetails(response.data[0]);
                
            }
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
}

export default getEventDetailsData;
