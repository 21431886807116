import React from "react";
import HeaderCoursesDropDown from "./HeaderCoursesDropDown";
import ReactGA from "react-ga";

const NavLinks = () => {
    return (
        <div className="header-nav-links-container">
            <HeaderCoursesDropDown />

            <a
                className="header-nav-link"
                target="_blank"
                rel="noreferrer"
                href="https://renaissance.programmingpathshala.com/crack-coding-interviews/stories"
                onClick={() =>
                    ReactGA.event({
                        category: "Button",
                        action: "Success Stories PPA Page (Header)",
                    })
                }
            >
                Success Stories
            </a>

            <a
                className="header-nav-link"
                target="_blank"
                rel="noreferrer"
                href="https://programmingpathshala.com/hire-from-us#"
                onClick={() =>
                    ReactGA.event({
                        category: "Button",
                        action: "Hire From Us (Header)",
                    })
                }
            >
                Hire From Us
            </a>

            <a
                className="header-nav-link"
                target="_blank"
                rel="noreferrer"
                href="https://programmingpathshala.com/campus-program#"
                onClick={() =>
                    ReactGA.event({
                        category: "Button",
                        action: "Campus Program (Header)",
                    })
                }
            >
                Campus Program
            </a>

            <a
                className="header-nav-link"
                target="_blank"
                rel="noreferrer"
                href="https://blogs.programmingpathshala.com/"
                onClick={() =>
                    ReactGA.event({
                        category: "Button",
                        action: "Blogs PPA Page (Header)",
                    })
                }
            >
                Blogs
            </a>
            <a
                className="header-nav-link"
                target="_blank"
                rel="noreferrer"
                href="https://events.programmingpathshala.com/"
                onClick={() =>
                    ReactGA.event({
                        category: "Button",
                        action: "Events PPA Page (Header)",
                    })
                }
            >
                Events
            </a>
        </div>
    );
};

export default NavLinks;
