import React from "react";
import Footer from "../../../Footer/Footer";
import Body from "./Body";
import Header from "../../../Header/Header";
import RepublicDayBanner from "../../../Banners/RepublicDayBanner";

function EventPageRoute() {
    return (
        <div className="landing-page-wrapper">
            <div>{/* <RepublicDayBanner /> */}</div>
            <div className="header-sticky">
                <Header />
            </div>
            <div id="body">
                <Body />
            </div>
            <div className="">
                <Footer />
            </div>
        </div>
    );
}

export default EventPageRoute;
