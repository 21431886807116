import React from "react";

import StartTrial from "../../StartTrial/StartTrial";
import { useState, useEffect } from "react";
import {
    EVENT_CARDS_PER_PAGE,
    MOBILE_SCREEN_WIDTH,
} from "../../../Constants/EventsConstants/EventsConstants";
import { useHistory } from "react-router-dom";
import EventCard from "../EventPage/EventCard/EventCard";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import "./styles/PreviousEvents.css";
import getPreviousEventsData from "./PreviousEventsClient";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import ExploreCourses from "../../ExploreCourses/ExploreCourses";
import RepublicDayBanner from "../../Banners/RepublicDayBanner";

function PreviousEvents() {
    const [prevEventDetails, setPrevEventDetails] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const history = useHistory();
    const width = window.innerWidth;

    // get current page events
    const [currentPage, setCurrentPage] = useState(1);
    const indexOfLastCard = currentPage * EVENT_CARDS_PER_PAGE;
    const indexOfFirstCard = indexOfLastCard - EVENT_CARDS_PER_PAGE;
    const currentCards = prevEventDetails.slice(indexOfFirstCard, indexOfLastCard);

    useEffect(() => {
        getPreviousEventsData(setPrevEventDetails, setLoading);

        return () => setPrevEventDetails([]);
    }, []);

    const handleClickOnUpcomingEvents = () => {
        history.push(`/Events`);
    };

    if (isLoading) {
        return (
            <div className="spinner">
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            {/* <RepublicDayBanner /> */}
            <Header />
            <div className="previousevents-wrapper">
                <div className="event-intro-wrapper">
                    <h3 className="eventpage-header">Previous Events</h3>
                    <p className="event-intro-description">
                        Our past events have been a huge success and learning point for attendees.
                        Events are a way to brush up on topics.... and network.
                    </p>
                </div>

                <section className="previousevents-cards-wrapper">
                    {currentCards.map((eventCard) => (
                        <EventCard cardContent={eventCard} isPrevious={true} />
                    ))}
                </section>

                <div className="upcoming-events">
                    <Button
                        className="upcoming-events-button"
                        onClick={handleClickOnUpcomingEvents}
                        variant="outlined"
                    >
                        View Upcoming Events
                    </Button>
                </div>
            </div>

            <ExploreCourses />
            <Footer />
        </>
    );
}

export default PreviousEvents;
