import React, { useState } from "react";
import "./styles/EventCard.css";
import participants from "../../../../Resources/Images/participants.svg";
import Paid_Yellow_Sticker from "../../../../Resources/Images/Paid_Yellow_Sticker.png";
import recording_tag from "../../../../Resources/Images/recording_tag.png";
import ShareEvent from "../ShareEvent/ShareEvent";
import { useHistory } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
    convertTime,
    convertToDateAndDay,
    eventInfoBox,
} from "../../../../Utilities/EventsUtility/EventsUtility";
import ActionButton from "../../../Actionbutton/ActionButton";
import { style } from "../../../Common/Theme";

const EventCard = (props) => {
    const cardContent = props.cardContent;
    const isPrevious = props.isPrevious;
    const history = useHistory();
    const eventImage = props.cardContent.event_image;
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOnModal = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOnEvent = () => {
        history.push(`/Events/${cardContent.event_id}`);
    };

    const handleClickOnRegister = () => {
        history.push(`/Events/${cardContent.event_id}`);
    };

    const getCardImage = () => {
        if (isPrevious)
            return (
                <div className="eventcard-image-wrapper">
                    <img src={eventImage} alt="" />
                </div>
            );

        return (
            <div>
                <div className="eventcard-image-wrapper" onClick={handleClickOnEvent}>
                    <img src={eventImage} alt="" />
                    {cardContent.event_type === "paid" && (
                        <div className="paid-sticker">
                            <img src={Paid_Yellow_Sticker} alt="" />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getCardTitle = () => {
        if (isPrevious) {
            return <div className="event-card-header">{cardContent.event_title}</div>;
        }

        return (
            <div
                style={{ cursor: "pointer" }}
                className="eventcard-header-wrapper"
                onClick={handleClickOnEvent}
            >
                <h3>{cardContent.event_title}</h3>
            </div>
        );
    };

    const getRegSection = () => {
        if (isPrevious)
            return (
                <div className="prev-btn-wrapper">
                    <button className="get-recorded-video-btn-wrapper">Watch Video</button>
                </div>
            );

        return (
            <div className="eventcard-reg-wrapper">
                <div className="eventcard-regbutton-wrapper" onClick={handleClickOnRegister}>
                    <ActionButton style={style} name="Register Now" />
                </div>
                {eventInfoBox(
                    participants,
                    `${cardContent.event_participants}+ people  already registered`,
                    "font-black"
                )}
            </div>
        );
    };

    return (
        <div className="eventcard-wrapper">
            <Modal open={isOpen} onClose={handleClickOnModal} className="share-event-modal">
                <ShareEvent
                    handleClickOnModal={handleClickOnModal}
                    event_details={cardContent}
                    event_image={eventImage}
                    event_url={`${window.location.href}/${cardContent.event_id}`}
                />
            </Modal>

            {getCardImage()}

            <div className="eventcard-info-wrapper">
                {getCardTitle()}
                <div
                    className={
                        isPrevious ? "eventcard-datetime prev-datetime" : "eventcard-datetime"
                    }
                >
                    {isPrevious && (
                        <div className="recorded-on-wrapper">
                            <img src={recording_tag} />
                        </div>
                    )}
                    <p> {isPrevious ? `Recorded On: ` : `Starts On: `}{convertToDateAndDay(cardContent.event_date_time)}{",  "}{convertTime(cardContent.event_date_time)}</p>
                </div>

                {getRegSection()}
                {!isPrevious && cardContent.recommended_for && <p className="recommended-for">{cardContent.recommended_for}</p>}
            </div>
        </div>
    );
};

export default EventCard;
