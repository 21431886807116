import React, { useState, useEffect } from "react";
import "./styles/Body.css";
import {
    EVENT_CARDS_PER_PAGE,
} from "../../../../Constants/EventsConstants/EventsConstants";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import EventCard from "../EventCard/EventCard";
import getEventsData from "./BodyClient";

import ExploreCourses from "../../../ExploreCourses/ExploreCourses";
import { useQuery } from "../../../../Utilities/CommonUtility/CommonUtility";
import { EVENT_CATEGORIES } from "../../../../Constants/EventsConstants/EventsConstants";
function Body() {
    const history = useHistory();
    const query = useQuery();
    const width = window.innerWidth;

    const [eventsData, setEventsData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);

        getEventsData(setEventsData, setLoading, query.get("type"));
        return () => setEventsData([]);
    }, []);

    const indexOfLastCard = currentPage * EVENT_CARDS_PER_PAGE;
    const indexOfFirstCard = indexOfLastCard - EVENT_CARDS_PER_PAGE;
    const currentCard = eventsData;

    if (isLoading) {
        return <div className="spinner">{/* <CircularProgress /> */}</div>;
    }

    const handleClickOnViewPrevious = () => {
        history.push(`/Previous-Events`);
    };

    return (
        <>
            <div className="event-intro-wrapper">
                <h3 className="eventpage-header">
                    {query.get("type") === EVENT_CATEGORIES.SUMMER_EVENT
                        ? "Summer Courses"
                        : "Upcoming Events and Workshops"}{" "}
                </h3>
                <p className="event-intro-description">
                    {query.get("type") === EVENT_CATEGORIES.SUMMER_EVENT
                        ? "Utilize this summer to learn relevant skills for your career advancement"
                        : "Get a brilliant learning experience first hand. Learn relevant skills with our Events and Workshops, and build in depth knowledge that takes your career to the next level.  Invite your friends and win exciting Rewards too!"}
                </p>
            </div>

            {eventsData.length !== 0 ? (
                <>
                    <div className="eventpage-wrapper">
                        <section className="eventpage-cards-wrapper">
                            {currentCard.map((eventCard) => (
                                <EventCard
                                    key={eventCard.event_id}
                                    cardContent={eventCard}
                                    isPrevious={false}
                                />
                            ))}
                        </section>
                    </div>
                   
                </>
            ) : (
                <div className="no-events-comment">No upcomming events available</div>
            )}
            <section className="previous-events">
                <Button
                    className="previous-events-button"
                    onClick={handleClickOnViewPrevious}
                    variant="outlined"
                >
                    View Previous Events
                </Button>
            </section>
            <ExploreCourses />
        </>
    );
}

export default Body;
