import React from "react";
import Button from "@mui/material/Button";
import ReactGA from "react-ga";

const ActionButton = (props) => {
    return (
        <div className="action-button">
            <Button
                sx={props.style}
                variant="contained"
                onClick={() => {
                    ReactGA.event({
                        category: "Button",
                        action: `${props.action}`,
                    });
                }}
            >
                {props.name}
            </Button>
        </div>
    );
};

export default ActionButton;
