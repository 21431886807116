import React from "react";
import "./styles/LinearGradientBtn.css";
import ReactGA from "react-ga";

const LinearGradientBtn = (props) => {
    return (
        <div className="linear-gradient-btn-container">
            <button
                className="linear-gradient-btn"
                data={props.name}
                onClick={() =>
                    ReactGA.event({
                        category: "Button",
                        action: `${props.action}`,
                    })
                }
            ></button>
        </div>
    );
};

export default LinearGradientBtn;
