import percntageImg from "./../Resources/Images/SharePercentageIcon.svg";
import TshirtIllustrator from "./../Resources/Images/TshirtIllustrator.svg";
import bagIllustrator from "./../Resources/Images/bagIllustrator.svg";
import keyBoardIllustrator from "./../Resources/Images/keyBoardIllustrator.svg";
import earphonesIllustrator from "./../Resources/Images/earphonesIllustrator.svg";

const giftCardContents = [
    {
        image: percntageImg,
        description: "20% of on any course",
        coinsEarned: 100,
    },
    {
        image: TshirtIllustrator,
        description: `"Code Till Infinity" T-shirt`,
        coinsEarned: 150,
    },
    {
        image: bagIllustrator,
        description: "Exclusive Laptop Bag",
        coinsEarned: 250,
    },
    {
        image: keyBoardIllustrator,
        description: "Logitech Keyboard",
        coinsEarned: 250,
    },
    {
        image: earphonesIllustrator,
        description: "boAt Earphones",
        coinsEarned: 400,
    },
];

export { giftCardContents };
