import React from "react";
import { giftCardContents } from "../../../Constants/EarnCoinsConstants";
import "./styles/EarnCoins.css";
import coinsIllustrator from "../../../Resources/Images/coinsIllustrator.svg";
const EarnCoins = (props) => {
    const getSectionTitle = () => {
        return (
            <div>
                <div className="earn-coins-title-wrapper">
                    <h3>SHARING IS CARING</h3>
                    {props.points && <p>Your Points: {props.points}</p>}
                </div>
                <p className="coin-refferal-to-point-conversion">1 referral = 100 points</p>
            </div>
        );
    };

    const getGiftItemsCard = () => {
        return (
            <div className="refer-gift-cards-wrapper">
                {giftCardContents &&
                    giftCardContents.map((data, index) => (
                        <div className="refer-gift-card" key={index}>
                            <div className="gift-image-wrapper">
                                <img src={data.image} alt="" />
                            </div>
                            <div className="card-details-wrapper">
                                <p className="refer-card-desc"> {data.description}</p>
                                <div className="card-coin-box">
                                    <p>{data.coinsEarned}</p>
                                    <div>
                                        <img src={coinsIllustrator} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        );
    };

    return (
        <div className="earn-coins-wrapper">
            {getSectionTitle()}
            {getGiftItemsCard()}
        </div>
    );
};

export default EarnCoins;
