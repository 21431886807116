import * as Yup from "yup";

import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "../../Constants/EventsConstants/EventsConstants";

const eventFormValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").matches(EMAIL_REGEX, "Email is inValid"),
    phone: Yup.string().required("Phone no. is required"),
});

const viewFormValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").matches(EMAIL_REGEX, "Email is inValid"),
});

const generateFormValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").matches(EMAIL_REGEX, "Email is inValid"),
    phone: Yup.string().required("Phone no. is required"),
});

const leadsFormValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone no. is required"),
});

export {
    eventFormValidationSchema,
    viewFormValidationSchema,
    generateFormValidationSchema,
    leadsFormValidationSchema,
};
