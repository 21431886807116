import arrowPrev from "../../Resources/Images/sliderArrowPrev.png";
import arrowNext from "../../Resources/Images/sliderNextArrow.png";
import ActionButton from "../Actionbutton/ActionButton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/ExploreEvents.css";
import { useEffect, useState } from "react";
import { useQuery } from "../../Utilities/CommonUtility/CommonUtility";
import getEventsData from "../Events/EventPage/Body/BodyClient";
import { style } from "../Common/Theme";
import participants from "../../Resources/Images/participants.svg";

import {
    convertTime,
    convertToDateAndDay,
    eventInfoBox,
} from "../../Utilities/EventsUtility/EventsUtility";
import { useHistory } from "react-router-dom";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const ExploreEvents = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [eventsData, setEventsData] = useState([]);
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        getEventsData(setEventsData, setLoading, query.get("type"));
        return () => setEventsData([]);
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: eventsData.length == 1 ? 1 : 2,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    const getHeader = () => {
        return (
            <div className="other-events-header-wrapper">
                <p>Explore Other Events</p>
            </div>
        );
    };

    const getSlidingStories = () => {
        return (
            <Slider {...sliderSettings}>
                {eventsData &&
                    eventsData.map((data, index) => (
                        <div className="other-events-card">
                            <div className="other-events-card-img-wrapper">
                                <img
                                    className="other-events-card-img"
                                    src={data.event_image}
                                    alt=""
                                />
                            </div>
                            <div className="other-events-card-content">
                                <h3>{data.event_title}</h3>
                                <div className="other-events-date-time-wrapper">
                                    <p className="starts-on-text">
                                        Starts On: {convertToDateAndDay(data.event_date_time)}
                                        {", "}
                                        {convertTime(data.event_date_time)}
                                    </p>
                                </div>

                                <div className="eventcard-reg-wrapper">
                                    <div
                                        className="eventcard-regbutton-wrapper"
                                        onClick={() =>
                                            window.open(
                                                `${process.env.REACT_APP_EVENTS_FRONTEND_URL}Events/${data.event_id}`,
                                                "_self"
                                            )
                                        }
                                    >
                                        <ActionButton style={style} name="Register Now" />
                                    </div>
                                    {eventInfoBox(
                                        participants,
                                        `${data.event_participants}+ people  already registered`,
                                        "font-black"
                                    )}
                                </div>
                                <p className="recommended-for">{data.recommended_for}</p>
                            </div>
                        </div>
                    ))}
            </Slider>
        );
    };

    return (
        <div className="learning-path-section">
            {getHeader()}
            {getSlidingStories()}
        </div>
    );
};

export default ExploreEvents;
