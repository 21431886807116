import React, { useState } from "react";
import "./styles/Curriculum.css";
import useWindowDimensions from "../../../Hooks/UseWindowDimensions";
import Accordion from "./Accordian";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import EventLeads from "../EventLeads";
import downloadIcon from "../../../Resources/Images/download-icon.svg";
import { isNULLEMPTYORUNDEFINED } from "../../../Utilities/CommonUtility/CommonUtility";
const Curriculum = (props) => {
    const { leadsFormModalOpen, setLeadsFormModalOpen } = useGlobalContext();
    const { windowWidth } = useWindowDimensions();

    const openLeadsFormModal = () => {
        setLeadsFormModalOpen(true);
    };

    const getCurriculum = () => {
        return (
            <div>
                <div className="course-includes-wrapper1">
                    <p className="course-includes-title">Course Curriculum</p>

                    <div className="course-included-container">
                        {props.event_details.event_curriculum &&
                            props.event_details.event_curriculum.map((accordionData, index) => (
                                <Accordion
                                    identifier={index + 1}
                                    key={index}
                                    accordionData={accordionData}
                                    event_type={props.event_details.event_type}
                                />
                            ))}
                    </div>
                    {!isNULLEMPTYORUNDEFINED(props.event_details.event_curriculum_pdf) && (
                        <>
                            <div
                                className="download-curriculum-wrapper"
                                onClick={() => openLeadsFormModal()}
                            >
                                Download Detailed Curriculum
                            </div>
                            <EventLeads event_details={props.event_details} />
                        </>
                    )}
                </div>
            </div>
        );
    };
    return <div>{props?.event_details?.event_curriculum ? getCurriculum() : <></>}</div>;
};

export default Curriculum;
