import SendCodeIllustrator from "../Resources/Images/SendCodeIllustrator.svg";
import useCouponCodellustrator from "../Resources/Images/useCouponCodellustrator.svg";
import getCouponIllustrator from "../Resources/Images/getCouponIllustrator.svg";
import coinsIllustrator from "../Resources/Images/coinsIllustrator.svg";
import giftBox from "../Resources/Images/giftBox.svg";

const howDoesItWorkCardData = [
    {
        image: SendCodeIllustrator,
        description: (
            <p className="step-card-description">
                Share your Unique <strong>“Referral link” </strong> with your friend👫
            </p>
        ),
    },
    {
        image: useCouponCodellustrator,
        description: (
            <p className="step-card-description">
                Your friend uses your Unique <strong> “Referral Code” </strong>and <br /> registers
                for the event.
            </p>
        ),
    },
    {
        image: getCouponIllustrator,
        description: (
            <p className="step-card-description">
                You get a Points <img className="steps-coin-image" src={coinsIllustrator} alt="" />
                which can be redeemed to <br /> <strong> Win Exciting Gifts! </strong>
                <img className="steps-gift-box" src={giftBox} alt="" />
            </p>
        ),
    },
];

export { howDoesItWorkCardData };
