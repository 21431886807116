import React from "react";
import RepublicDayBanner from "../Banners/RepublicDayBanner";
import ExploreEvents from "../ExploreEvents/ExploreEvents";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import ReferYourFriendBanner from "./ReferYourFriendBanner/ReferYourFriendBanner";
import "./styles/ReferYourFriend.css";
const ReferYourFriend = () => {
    return (
        <div>
            {/* <RepublicDayBanner /> */}
            <Header />
            <ReferYourFriendBanner />
            <ExploreEvents />
            <Footer />
        </div>
    );
};

export default ReferYourFriend;
