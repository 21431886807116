import React from 'react'
import "./WorkshopBenefitForCareer.css";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/CommonUtility/CommonUtility";

function WorkshopBenefitForCareer(props) {
    const careerBenefits = props.event_details.event_career_benefits;

    // return empty component if there is no career benefits
    if(isNULLEMPTYORUNDEFINED(careerBenefits) || isNULLEMPTYORUNDEFINED(careerBenefits[0]))
        return <></>;

    return (
        <div className="workshop-career-benefit-wrapper">
            <h3 className="workshop-career-benefit-heading">How will this workshop help you in your career?</h3>
            <div className="workshop-career-benefit-card-wrapper">
                {careerBenefits &&
                    careerBenefits.map((benefit) => (
                        <div className="workshop-career-benefit-card">
                            <div className="workshop-career-benefit-card-tick">
                                <WorkspacePremiumIcon className='workshop-career-benefit-badge-icon'/>
                            </div>
                            <p>{benefit}</p>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default WorkshopBenefitForCareer