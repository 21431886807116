import axios from "axios";
import { MISC_URL } from "../../../../Constants/UrlConstants";
import {
    EVENT_CATEGORIES,
    EVENT_TYPES,
    LPU_EVENT_IDS,
    SUMMER_EVENT_IDS,
} from "../../../../Constants/EventsConstants/EventsConstants";
function getEventsData(setEventsData, setLoading, type) {
    axios
        .get(
            // "https://backend-staging.programmingpathshala.com/rest/misc/getAllEvents"
            `${process.env.REACT_APP_MISC_URL}/getAllEvents`
        )
        .then((response) => {
            if (type && type === EVENT_CATEGORIES.SUMMER_EVENT) {
                const summerEvents =
                    response.data &&
                    response.data.filter(function (event) {
                        return SUMMER_EVENT_IDS.includes(event.event_id);
                    });
                setEventsData(summerEvents);
            } else if (type && type === EVENT_CATEGORIES.FREE_EVENT) {
                const freeEvents =
                    response.data &&
                    response.data.filter(function (event) {
                        return event.event_type === EVENT_TYPES.FREE;
                    });
                setEventsData(freeEvents);
            } else {
                const lpuEventsHided =
                    response.data &&
                    response.data.filter(function (event) {
                        return !LPU_EVENT_IDS.includes(event.event_id);
                    });
                setEventsData(lpuEventsHided);
            }

            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
}

export default getEventsData;
