import React from "react";
import closeIcon from "../../Resources/Images/dropDownOpenIconBlack.svg";
import openIcon from "../../Resources/Images/dropDownOpenIcon.svg";
import "./styles/CustomMadeAccordion.css";
const CustomMadeAccordion = (props) => {
    const handleAccordionClick = () => {
        props.recordAccordionId(props.identifier);
    };

    const getAccordion = () => {
        return (
            <div onClick={handleAccordionClick} className="custom-accordion-wrapper">
                <div
                    className={
                        (props.isOpen ? "custom-accordion-background" : " ") +
                        " custom-accordion-item"
                    }
                >
                    <div className="custom-accodrion-title">
                        <h2 className="custom-accordion-questions">
                            {props.accordionData.question}
                        </h2>
                        <span>
                            {props.isOpen ? (
                                <img src={closeIcon} className="open-close-img-faq-up" alt="" />
                            ) : (
                                <img src={openIcon} className="open-close-img-faq" alt="" />
                            )}
                        </span>
                    </div>

                    <div
                        className={
                            (props.isOpen ? "custom-accodrion-show" : " ") +
                            " custom-accodrion-content"
                        }
                    >
                        <p className="custom-accordion-answer">{props.accordionData.answer}</p>
                    </div>
                </div>
            </div>
        );
    };

    return <div>{getAccordion()}</div>;
};

export default CustomMadeAccordion;
