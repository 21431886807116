import { logoSvg, header } from "../../Constants/HeaderConstants";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

var browserList = [
    { name: "Firefox", value: "Firefox" },
    { name: "Opera", value: "OPR" },
    { name: "Edge", value: "Edg" },
    { name: "Chrome", value: "Chrome" },
    { name: "Safari", value: "Safari" },
];

let getBrowserDetails = () => {
    let userDetails = navigator.userAgent;
    for (let i in browserList) {
        if (userDetails.includes(browserList[i].value)) {
            return browserList[i].name || "Unknown Browser";
        }
    }
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const nullCheckIncomingParams = (query) => {
    return (
        (query.get("utm_source") ||
            query.get("utm_campaign") ||
            query.get("utm_medium") ||
            query.get("utm_term") ||
            query.get("utm_content")) == null
    );
};

const nullCheckExistingParams = () => {
    const utmParams = JSON.parse(localStorage.getItem("utmParameters"));
    return (
        utmParams == null ||
        (utmParams.utmSource ||
            utmParams.utmCampaign ||
            utmParams.utmMedium ||
            utmParams.utmTerm ||
            utmParams.utmContent) == null
    );
};

const setUTMParametersInLocalStorage = (query) => {
    if (!nullCheckExistingParams() && nullCheckIncomingParams(query)) return;
    const utmParameters = {
        utmSource: query.get("utm_source"),
        utmCampaign: query.get("utm_campaign"),
        utmMedium: query.get("utm_medium"),
        utmTerm: query.get("utm_term"),
        utmContent: query.get("utm_content"),
    };

    localStorage.setItem("utmParameters", JSON.stringify(utmParameters));
};

const setAffiliateIdInLS = (searchParams) => {
    searchParams.get("ref") && localStorage.setItem("affiliate_id", searchParams.get("ref"));
}

const getUserAdDetails = () => {
    const utmParams = JSON.parse(localStorage.getItem("utmParameters"));
    const userAdDetails = {
        utm_source: utmParams.utmSource,
        utm_medium: utmParams.utmMedium,
        utm_campaign: utmParams.utmCampaign,
        utm_term: utmParams.utmTerm,
        utm_content: utmParams.utmContent,
        referrer: document.referrer,
        browser: getBrowserDetails(),
        device: isMobile ? "Mobile" : "Desktop",
        location_id: window.location.href,
        event_type: "unpaid event",
    };
    localStorage.removeItem("utmParameters");
    return userAdDetails;
};

const isNULLEMPTYORUNDEFINED = (value) => {
    return value === null || value === undefined || value === "";
};

let validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !isNULLEMPTYORUNDEFINED(email) && re.test(String(email).toLowerCase());
};

let validatePhonenumber = (inputNumber) => {
    let PHONE_NUMBER_REGEX = /^((\91?)|\+)?[7-9][0-9]{9}$/;
    return (
        !isNULLEMPTYORUNDEFINED(inputNumber) &&
        inputNumber.length === 12 &&
        PHONE_NUMBER_REGEX.test(inputNumber)
    );
};

const PPALogo = () => {
    return (
        <div className="PPA-logo">
            {logoSvg}
            {header}
        </div>
    );
};

export {
    validateEmail,
    validatePhonenumber,
    PPALogo,
    useQuery,
    setUTMParametersInLocalStorage,
    getUserAdDetails,
    nullCheckExistingParams,
    isNULLEMPTYORUNDEFINED,
    setAffiliateIdInLS
};
