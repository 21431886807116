import amazonImg from "./CompanyLogo/amazon.svg";
import apple from "./CompanyLogo/apple.svg";
import flipkartImg from "./CompanyLogo/flipkart.svg";
import google from "./CompanyLogo/google.svg";
import linkedInImg from "./CompanyLogo/linkedin.svg";
import microsoft from "./CompanyLogo/microsoft.svg";
import uberImg from "./CompanyLogo/uber.svg";

const companiesImages = {
    amazon: amazonImg,
    linkedin: linkedInImg,
    flipkart: flipkartImg,
    uber: uberImg,
    apple: apple,
    google: google,
    microsoft: microsoft,
};

export { companiesImages };
