import arrowRight from "../../Resources/Images/arrow-right.png";
import arrowPrev from "../../Resources/Images/sliderArrowPrev.png";
import arrowNext from "../../Resources/Images/sliderNextArrow.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/ExploreCourses.css";
import { courseCardDetails } from "./ExploreCoursesCardDetails";

function NextArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowNext} alt="next" />;
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return <img className={className} onClick={onClick} src={arrowPrev} alt="prev" />;
}

const ExploreCourses = () => {
    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3,
        arrows: true,
        slidesToScroll: 1,
        customPaging: function (i) {
            return <a />;
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 601,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    const getHeader = () => {
        return (
            <div className="learning-path-header-wrapper">
                <p className="learning-path-title">Explore Our Courses</p>
            </div>
        );
    };

    const getSlidingStories = () => {
        return (
            <Slider {...sliderSettings}>
                {courseCardDetails &&
                    courseCardDetails.map((data, index) => (
                        <a
                            href={data.clickable ? data.courseLink : ""}
                            className="learning-path-course-card"
                            key={index}
                        >
                            <div className="learning-path-course-card-img-wrapper">
                                <img
                                    className="learning-path-course-card-img"
                                    src={data.courseBannerImg}
                                    alt=""
                                />
                            </div>
                            <p className="learning-path-course-card-desc ">
                                {data.courseDescription}
                            </p>

                            <div
                                className={
                                    data.ctaButton == "Coming Soon"
                                        ? `learning-path-course-card-btn coming-soon`
                                        : "learning-path-course-card-btn"
                                }
                            >
                                {data.ctaButton}{" "}
                                {data.ctaButton != "Coming Soon" && (
                                    <div className={"arrow-right"}>
                                        <img src={arrowRight} alt="" />
                                    </div>
                                )}
                            </div>
                        </a>
                    ))}
            </Slider>
        );
    };

    return (
        <div className="learning-path-section">
            {getHeader()}
            {getSlidingStories()}
        </div>
    );
};

export default ExploreCourses;
