import renCourseImg from "../../Resources/Images/renCourseImg.svg";
import LLdcourseImg from "../../Resources/Images/LLdcourseImg.svg";
import beginwithCppImg from "../../Resources/Images/beginwithC++Img.png";
import DSAImg from "../../Resources/Images/DSA.png";
import reactImg from "../../Resources/Images/reactImg.svg";
import pythonImg from "../../Resources/Images/python.svg";

const courseCardDetails = [
    {
        courseBannerImg: renCourseImg,
        courseDescription:
            "Master Data Structures & Algorithms and System Design. Crack Top-notch Tech roles.",
        ctaButton: "Know More",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}crack-coding-interviews`,
        clickable: true,
    },

    {
        courseBannerImg: LLdcourseImg,
        courseDescription:
            "Design and write highly extensible, maintainable and readable code. Mandatory skill for Senior Engineers.",
        ctaButton: "Know More",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}low-level-design`,
        clickable: true,
    },
    {
        courseBannerImg: DSAImg,
        courseDescription:
            "A very concise program with curated and handpicked concepts, for you to master DSA quickly and crack your dream job.",
        ctaButton: "Know More",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}dsa-and-algorithms-essentials`,
        clickable: true,
    },
    {
        courseBannerImg: reactImg,
        courseDescription:
            "Master the skills of a front-end engineer and build modern, responsive websites",
        courseLink: `${process.env.REACT_APP_FRONTEND_MAIN_URL}become-a-frontend-engineer`,
        clickable: true,
        ctaButton: "Know More",
    },
    {
        courseBannerImg: beginwithCppImg,
        courseDescription:
            "A practical and hands-on course for those who are eager to master C++. Beginner-friendly.",
        ctaButton: "Coming Soon",
        courseLink: "",
        clickable: false,
    },

    {
        courseBannerImg: pythonImg,
        courseDescription:
            "Become a Python pro and unlock the door to endless career opportunities",
        clickable: false,
        ctaButton: "Coming Soon",
    },
];
export { courseCardDetails };
