import React from "react";
import { getCertificateContents } from "./TestimonialsAndCertificateConstants";
import certificate from "../../Resources/Images/Certificate.svg";
const Certificate = () => {
    return (
        <div className="certificates-wrapper">
            <div className="certificate-container">
                <h3 className="common-section-title">Certificate of Completetion</h3>
                <div className="certificate-wrapper">
                    <div className="certificate-img-wrapper">
                        <img src={certificate} alt="" />
                    </div>
                    <div className="certificate-features-wrapper">
                        {getCertificateContents &&
                            getCertificateContents.map((data, index) => (
                                <div className="certificate-content-details-wrapper" key={index}>
                                    <img src={data.image} alt="" />

                                    <div>
                                        <p>{data.title}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Certificate;
