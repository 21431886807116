const convertDate = (dateTime) => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const localDate = new Date(dateTime);
    const date = localDate.getDate();
    const month = localDate.getMonth();
    const year = localDate.getFullYear();

    const convertedDate = `${date} ${months[month]} ${year}`;

    return convertedDate;
};

const convertToDateAndDay = (dateTime) => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const localDate = new Date(dateTime);
    const date = localDate.getDate();
    const month = localDate.getMonth();
    const day = localDate.getDay();
    
    const year = localDate.getFullYear();
    const convertedDate = `${days[day]}, ${date} ${months[month]} ${year}`;

    return convertedDate;
};

const convertTime = (dateTime) => {
    const convertedTime = new Date(dateTime)
        .toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        })
        .toUpperCase();

    const timeZoneAbbr = new Date(dateTime)
        .toLocaleDateString("en-US", {
            day: "2-digit",
            timeZoneName: "long",
        })
        .slice(4)
        .split(" ");

    const timezone = timeZoneAbbr
        .map((word) => {
            return word[0];
        })
        .join("");

    return convertedTime + " " + timezone;
};

const eventInfoBox = (icon, info, fontColor) => {
    return (
        <div className="infobox">
            {icon ? <img alt="" src={icon} /> : <></>}
            <p className={`${fontColor}`}> {info} </p>
        </div>
    );
};

export { convertDate, convertTime, eventInfoBox, convertToDateAndDay };
