import axios from "axios";

const generateReferralCode = (
    data,
    setReferralCode,
    setLoader,
    setViewGenerateSuccessMsg,
    setViewGenerateSuccessModalOpen
) => {
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/generateReferralCode`, data)
        .then((response) => {
            setLoader(false);
            if (response && response.data && response.data.referralCode) {
                setReferralCode(response.data.referralCode);
                setViewGenerateSuccessModalOpen(true);
                setViewGenerateSuccessMsg("your referral code is " + response.data.referralCode);
            }
        })
        .catch((error) => {
            setLoader(false);
            setViewGenerateSuccessModalOpen(false);
        });
};

const getReferralCode = (
    email,
    event_id,
    setReferralCode,
    setLoader,
    setViewGenerateSuccessMsg,
    setViewGenerateSuccessModalOpen
) => {
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/getReferralCode`, {
            email: email,
            event_id:event_id
        })
        .then((response) => {
            setLoader(false);
            if (response && response.data) {
                setViewGenerateSuccessModalOpen(true);
                if (response.data.isPresent) {
                    setViewGenerateSuccessMsg(
                        "Your referral code is " + response.data.referralCode
                    );
                    setReferralCode(response.data.referralCode);
                } else {
                    setViewGenerateSuccessMsg(
                        "You do not have a referral Code! Pls generate a new one"
                    );
                }
            }
        })
        .catch((error) => {
            setLoader(false);
            setViewGenerateSuccessModalOpen(false);
        });
};

const getUserPoints = (eventId, referralCode, setUserPoints) => {
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/getUserPoints`, {
            referralCode: referralCode,
            eventId:eventId
        })
        .then((response) => {
            if (response && response.data && response.data.user_points) {
                setUserPoints(response.data.user_points);
            }
        })
        .catch((error) => {});
};

const getLeaderBoard = (eventId, setLeaderBoard) => {
    axios
        .post(`${process.env.REACT_APP_MISC_URL}/getEventsLeaderBoard`,{
            eventId:eventId
        })
        .then((response) => {
            if (response && response.data && response.data.Response) {
                setLeaderBoard(response.data.Response);
            }
        })
        .catch((error) => {});
};
export { generateReferralCode, getReferralCode, getUserPoints, getLeaderBoard };
