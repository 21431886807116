import axios from "axios";
import { MISC_URL } from "../../../Constants/UrlConstants";

function getPreviousEventsData(setPrevEventDetails, setLoading) {
    axios
        // .get("https://backend-staging.programmingpathshala.com/rest/misc/getPreviousEvents")
        .get(`${process.env.REACT_APP_MISC_URL}/getPreviousEvents`)
        .then((eventData) => {
            setPrevEventDetails(eventData.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
        });
}

export default getPreviousEventsData;
