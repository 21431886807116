import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const GlobalContext = React.createContext();

export function useGlobalContext() {
    return useContext(GlobalContext);
}

export function GlobalContextProvider({ children }) {
    const [currentLoggedInUserEmail, setCurrentLoggedInUserEmail] = useState("");
    const [currentLoggedInUserName, setCurrentLoggedInUserName] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);
    const [subscribed, setSubscribed] = useState(false);
    const [onFreeTrial, setOnFreeTrial] = useState(true);
    const [errorSnackbar, setErrorSnackbar] = useState(false);
    const [globalErrorMessage, setGlobalErrorMessage] = useState("");
    const [showOtpScreen, setShowOtpScreen] = useState(false);

    const [checkForUpperCaseLetter, setCheckForUpperCaseLetter] = useState(false);
    const [checkSpecialCharacter, setCheckSpecialCharacter] = useState(false);
    const [checkCharacterLength, setCheckCharacterLength] = useState(false);
    const [couponDiscountPrice, setCouponDiscountPrice] = useState();

    const [leadsFormModalOpen, setLeadsFormModalOpen] = useState(false);
    const [consultationLeadsModalOpen, setConsultationLeadsModalOpen] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);

    const [registerFormModalOpen, setRegisterFormModalOpen] = useState(false);

    const setCurrentLoggedInUserInState = () => {
        const cookies = new Cookies();
        let token = cookies.get("authorization");
        if (!token) return;
        let decodedToken = jwt_decode(token);
        setCurrentLoggedInUserEmail(decodedToken.email);
    };

    const setCurrentLoggedInUserNameInState = () => {
        const cookies = new Cookies();
        axios
            .get(`${process.env.REACT_APP_UTILITY_URL}/getUsername`, {
                headers: {
                    authorization: cookies.get("authorization"),
                },
            })
            .then((response) => {
                if (response && response.data) setCurrentLoggedInUserName(response.data.full_name);
                checkSubscribed();
            })
            .catch((error) => {
                setGlobalErrorMessage("Error fetching name");
                setErrorSnackbar(true);
            });
    };

    const checkSubscribed = () => {
        const cookies = new Cookies();
        axios
            .get(
                process.env.REACT_APP_UTILITY_URL + "isSubscribed",
                {
                    headers: {
                        authorization: cookies.get("authorization"),
                    },
                },
                {
                    params: {
                        username: currentLoggedInUserEmail,
                    },
                }
            )
            .then((response) => {
                if (
                    response.data.Response.Subscription.status &&
                    !response.data.Response.Subscription.onTrial
                ) {
                    setLoggedIn(true);
                    setSubscribed(true);
                    setOnFreeTrial(false);
                }
                if (
                    response.data.Response &&
                    response.data.Response.Subscription &&
                    response.data.Response.Subscription.status &&
                    response.data.Response.Subscription.onTrial
                ) {
                    setLoggedIn(true);
                    setSubscribed(true);
                    setOnFreeTrial(true);
                } else {
                    setLoggedIn(true);
                }
            })
            .catch((error) => {});
    };

    const initiateLogout = () => {
        const cookies = new Cookies();
        if (cookies.get("id_token"))
            cookies.remove("id_token", {
                path: "/",
                domain: ".programmingpathshala.com",
            });
        if (cookies.get("access_token"))
            cookies.remove("access_token", {
                path: "/",
                domain: ".programmingpathshala.com",
            });
        if (cookies.get("authorization"))
            cookies.remove("authorization", {
                path: "/",
                domain: ".programmingpathshala.com",
            });
        window.open(process.env.REACT_APP_FRONTEND_MAIN_URL, "_self");
    };

    useEffect(() => {
        setCurrentLoggedInUserInState();
        setCurrentLoggedInUserNameInState();
    }, []);

    const value = {
        setCurrentLoggedInUserInState,
        setCurrentLoggedInUserNameInState,
        loggedIn,
        subscribed,
        onFreeTrial,
        currentLoggedInUserEmail,
        setCurrentLoggedInUserEmail,
        currentLoggedInUserName,
        initiateLogout,
        showOtpScreen,
        setShowOtpScreen,
        couponDiscountPrice,
        setCouponDiscountPrice,
        checkForUpperCaseLetter,
        setCheckForUpperCaseLetter,
        checkSpecialCharacter,
        setCheckSpecialCharacter,
        checkCharacterLength,
        setCheckCharacterLength,
        isValidPassword,
        setIsValidPassword,
        leadsFormModalOpen,
        setLeadsFormModalOpen,
        registerFormModalOpen,
        setRegisterFormModalOpen,
        consultationLeadsModalOpen,
        setConsultationLeadsModalOpen
    };
    return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
}
