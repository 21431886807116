import React from "react";
import "./styles/Community.css";
import community from "../../Resources/Images/community-image.png";

const Community = () => {
    const handleClick = () => {
        window.open("https://community.programmingpathshala.com/");
    };
    return (
        <div className="community-wrapper">
            <div className="community-image">
                <img src={community} alt="" />
            </div>
            <div className="community-content">
                <h1>Join our Community, and Code Everyday.</h1>
                <p>
                    Consistency is what makes you perfect. Solve Problems
                    <br /> everyday with us by joining the Community.
                </p>
                <button className="join-community-btn" onClick={handleClick}>
                    Join Our Community
                </button>
            </div>
        </div>
    );
};

export default Community;
