import React from "react";
import whatsappIcon from "../../Resources/Images/whatsappIcon.svg";
import {
    WHATS_APP_LINK,
    PPA_ADMIN_PHONE_NUMBER,
    ADMIN_WHATSAPP_MESSAGE,
} from "../../Constants/EventsConstants/EventsConstants";
import "./styles/TalkToUsButton.css";
const TalkToUsButton = () => {
    const openWhatsapp = () => {
        window.open(WHATS_APP_LINK + PPA_ADMIN_PHONE_NUMBER + ADMIN_WHATSAPP_MESSAGE, "_blank");
    };
    return (
        <div className="Talk-to-us-wrapper-whatsapp" onClick={openWhatsapp}>
            <div className="chat-with-expert">
                <img src={whatsappIcon} width={30} alt="text-icon" />
                <p className="chat-with-expert-content">Talk to Us</p>
            </div>
        </div>
    );
};

export default TalkToUsButton;
