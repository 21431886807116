import React from "react";
import { howDoesItWorkCardData } from "../../../Constants/HowDoesItWorkConstants";
import useWindowDimensions from "../../../Hooks/UseWindowDimensions";
import stepper from "../../../Resources/Images/stepper.svg";
import stepperMob from "../../../Resources/Images/stepperMob.svg";
import "./styles/HowDoesItWork.css";

const HowDoesItWork = () => {
    const { windowWidth } = useWindowDimensions();

    const gettheStepsAndCards = () => {
        return (
            <div className="steps-and-cards-wrapper">
                <div className="stepper-image-wrapper">
                    {windowWidth > 600 ? (
                        <img src={stepper} alt="" />
                    ) : (
                        <img src={stepperMob} alt="" />
                    )}
                </div>
                <div>
                    {howDoesItWorkCardData &&
                        howDoesItWorkCardData.map((data, index) => (
                            <div className="steps-card-and-content-wrapper">
                                <div>
                                    <img src={data.image} alt="" />
                                </div>
                                <div>{data.description}</div>
                            </div>
                        ))}
                </div>
            </div>
        );
    };

    return (
        <div>
            <h3 className="how-does-it-work-heading">How Does It Work?</h3>
            <div>{gettheStepsAndCards()}</div>
        </div>
    );
};

export default HowDoesItWork;
