import axios from "axios";
import "./styles/LeaderBoard.css";
const LeaderBoard = (props) => {
    return (
        <div className="leaderboard-wrapper">
            <div className="leaderboard-wrapper-title">
                <span className="top-three">Top 3</span>Leaderboard
            </div>
            <table>
                <tr>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Referrals</th>
                    <th>Coins</th>
                </tr>

                {props.data &&
                    props.data.map((userPoints) => (
                        <tr className={userPoints.email === props.user ? "row-user" : "row"}>
                            <td>{userPoints.name}</td>
                            <td>{userPoints.phone_number.slice(0, 5)}*****</td>
                            <td>{userPoints.points / 100}</td>
                            <td>{userPoints.points}</td>
                        </tr>
                    ))}
            </table>
        </div>
    );
};

export default LeaderBoard;
