import React, { useState } from "react";
import "./styles/Accordian.css";
import closeIcon from "../../../Resources/Images/dropDownOpenIcon.svg";
import openIcon from "../../../Resources/Images/dropDownCloseIconBlack.svg";
import useWindowDimensions from "../../../Hooks/UseWindowDimensions";
import curriculumTick from "../../../Resources/Images/curriculumTick.svg";
import { EVENT_TYPES } from "../../../Constants/EventsConstants/EventsConstants";

const Accordion = (props) => {
    const { windowWidth } = useWindowDimensions();
    const [clicked, setClicked] = useState(false);
    const handleClick = () => {
        if (props.accordionData.points) {
            setClicked((prev) => !prev);
        }
    };

    // const handleAccordionClick = () => {
    //     props.recordAccordionId(props.identifier);
    // };

    return (
        <div onClick={handleClick} className="curriculum-accordion-wrapper">
            <div className="curriculum-accordion-item">
                <div className="curriculum-accodrion-title">
                    <div className="curriculum-accodrion-with-dropdown">
                        <span>
                            {clicked ? (
                                <img className="open-close-img-close" src={closeIcon} alt="" />
                            ) : (
                                <img className="open-close-img" src={openIcon} alt="" />
                            )}
                        </span>
                        <h2
                            className={
                                clicked ? "curriculum-open-heading" : "curriculum-close-heading"
                            }
                        >
                            {props.accordionData.heading}
                        </h2>
                    </div>
                    <span className="accordion-module">module {props.identifier}</span>
                </div>

                <div
                    className={
                        (clicked ? "curriculum-accodrion-show" : " ") +
                        " curriculum-accodrion-content"
                    }
                >
                    <div className="curriculum-accordion-answer">
                        {props.accordionData.points &&
                            props.accordionData.points.map((point) => (
                                <div className="accordian-point-img-wrapper">
                                    <img src={curriculumTick} alt="" />
                                    <p>{point}</p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accordion;
