import React from "react";
import ppaLogo from "../../Resources/Images/ppa-footer-logo.svg";
import facebookIcon from "../../Resources/Images/footer-fb.png";
import youtubeIcon from "../../Resources/Images/footer-yt.png";
import linkedInIcon from "../../Resources/Images/footer-ln.png";
import telegramIcon from "../../Resources/Images/footer-tg.png";
import instagramIcon from "../../Resources/Images/footer-ig.png";
import { Link } from "react-router-dom";
import "./styles/Footer.css";

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-logo">
                <img src={ppaLogo} alt="" />
                <p className="footer-title">Programming Pathshala</p>
            </div>
            <div className="footer-links-container">
                <div className="footer-courses-content">
                    <div className="footer-course-content-column">
                        <p className="courses-heading">COURSES</p>

                        <a
                            href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}crack-coding-interviews`}
                        >
                            <p className="courses-links">Renaissance for Top Tech Companies</p>
                        </a>
                        <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}low-level-design`}>
                            <p className="courses-links">Low Level Design and Concurrency</p>
                        </a>
                        <a
                            href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}dsa-and-algorithms-essentials`}
                        >
                            <p className="courses-links">
                                Crash Course in Data Structures & Algorithms
                            </p>
                        </a>
                        <p className="courses-links">Backend Development with Java Spring Boot</p>
                        <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-c++`}>
                            <p className="courses-links">Begin with Programming in C++</p>
                        </a>
                    </div>
                    <div className="other-upcoming-courses">
                        <span></span>
                        <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}begin-with-python`}>
                            <p className="courses-links">Become a Python Expert</p>
                        </a>
                        <p className="courses-links">System Design for Professionals</p>
                        <p className="courses-links">Be a Data Engineer</p>
                        <p className="courses-links">Full Stack Development in MERN</p>
                    </div>
                </div>

                <div className="footer-options">
                    <div className="footer-options-column">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://programmingpathshala.com/"
                            className="links-font-style"
                            to=""
                        >
                            <p className="courses-links">About Us</p>
                        </a>
                        <a
                            href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}crack-coding-interviews/stories`}
                        >
                            <p className="courses-links">Success Stories</p>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://blogs.programmingpathshala.com/"
                        >
                            <p className="courses-links">Blogs</p>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://events.programmingpathshala.com/"
                        >
                            <p className="courses-links">Events</p>
                        </a>
                    </div>

                    <div className="footer-other-content">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/nG8FArKkwNEJucqE7"
                            className="links-font-style"
                            to=""
                        >
                            <p className="courses-links">Get Scholarships</p>
                        </a>

                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://forms.gle/jsepbL2id7gpkSLeA"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p className="courses-links">Teach with Us</p>
                        </a>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://community.programmingpathshala.com/"
                            className="links-font-style"
                            to=""
                        >
                            {" "}
                            <p className="courses-links"> Join our Community</p>
                        </a>
                        <div className="policies">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}terms-and-conditions`}
                                className="links-font-style"
                                to=""
                            >
                                <p className="courses-links">Terms and Conditions</p>
                            </a>
                            <a href={`${process.env.REACT_APP_FRONTEND_MAIN_URL}/refund-policy`}>
                                <p className="courses-links">Refund Policy</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="share-icons-container">
                <a
                    href="https://www.facebook.com/Programmingpathshala.1"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="share-icon" src={facebookIcon} alt="" />
                </a>
                <a
                    href="https://www.youtube.com/channel/UC4uPfwRHPbYlmjNMiKIdWNg"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="share-icon" src={youtubeIcon} alt="" />
                </a>
                <a
                    href="https://www.linkedin.com/school/programming-pathshala/mycompany/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {" "}
                    <img className="share-icon" src={linkedInIcon} alt="" />
                </a>
                <a
                    href="https://t.me/programmingpathshala"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="share-icon" src={telegramIcon} alt="" />
                </a>
                <a
                    href="https://www.instagram.com/programmingpathshala/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="share-icon" src={instagramIcon} alt="" />
                </a>
            </div>
            <div className="footer-copy-right-container">
                <p>All rights reserved with @Programming Pathshala</p>
            </div>
        </div>
    );
};

export default Footer;
