import React from "react";
import LoginButton from "./LoginButton";
import NavLinks from "./NavLinks";
import logo from "../../Resources/Images/PpaLogo.svg";
import HeaderForMob from "./HeaderForMob";
import { useState, useEffect } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import useWindowDimensions from "../../Hooks/UseWindowDimensions";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import "./styles/Header.css";
import BannerRibbon from "./BannerRibbon";
import { isNULLEMPTYORUNDEFINED } from "../../Utilities/CommonUtility/CommonUtility";

const Header = (props) => {
    const history = useHistory();
    const [buttonName, setButtonName] = useState("Sign Up");
    const { loggedIn, initiateLogout, setRegisterFormModalOpen } = useGlobalContext();

    const { windowWidth } = useWindowDimensions();
    const listenToScroll = () => {
        let height = 400;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        setButtonName(winScroll);

        if (height >= winScroll) {
            setButtonName("Sign Up");
        } else {
            setButtonName("Start 7 Day Free Trial");
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    const getHeaderforDesktop = () => {
        return (
            <div className="header-links">
                <div className="ppa-logo-container">
                    <a target="_blank" rel="noreferrer" href="https://programmingpathshala.com/">
                        <img className="ppa-header-logo" src={logo} alt="" />
                    </a>
                </div>

                <NavLinks />

                <div>{getHeaderBtnForDeskTop()}</div>
            </div>
        );
    };
    const scrollToRegForm = () => {
        if (!isNULLEMPTYORUNDEFINED(props.event_details.intro_video_link)) {
            setRegisterFormModalOpen(true);
        } else {
            if (windowWidth > 600) {
                window.scrollTo({ top: 130, left: 0, behavior: "smooth" });
            } else {
                window.scrollTo({ top: 500, left: 0, behavior: "smooth" });
            }
        }
    };

    const getHeaderBtnForDeskTop = () => {
        if (!loggedIn) {
            if (props.isEventsPage) {
                return (
                    <div onClick={() => scrollToRegForm()}>
                        <button className="sign-up-btn">Register</button>
                    </div>
                );
            }
            return (
                <div className="header-buttons">
                    <div
                        onClick={() => {
                            window.open(`${process.env.REACT_APP_FRONTEND_MAIN_URL}login`, "_self");
                            ReactGA.event({
                                category: "Button",
                                action: "Login - PPA Page",
                            });
                        }}
                    >
                        <p className="log-in-or-out-btn">Log In</p>
                    </div>
                    <div
                        onClick={() => {
                            window.open(
                                `${process.env.REACT_APP_FRONTEND_MAIN_URL}signup`,
                                "_self"
                            );
                            ReactGA.event({
                                category: "Button",
                                action: "Sign Up - PPA Page",
                            });
                        }}
                    >
                        <button className="sign-up-btn">{buttonName}</button>
                    </div>
                </div>
            );
        } else {
            if (props.isEventsPage) {
                return (
                    <div onClick={() => scrollToRegForm()}>
                        <button className="sign-up-btn">Register</button>
                    </div>
                );
            }
            return (
                <div onClick={() => initiateLogout()}>
                    <p className="log-in-or-out-btn">Log Out</p>
                </div>
            );
        }
    };

    const goToLoginPage = () => {
        window.open("https://renaissance.programmingpathshala.com/signup", "_self");
    };
    return (
        <div className=" header-container">
            {windowWidth > 600 ? getHeaderforDesktop() : <HeaderForMob />}

            {windowWidth <= 600 ? (
                <>
                    <div className="ppa-header-logo-wrapper">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://programmingpathshala.com/"
                        >
                            <img
                                className="ppa-logo"
                                src={logo}
                                alt="ppa-logo"
                                width={220}
                                height={35}
                            />
                        </a>
                    </div>
                    <div
                        onClick={() =>
                            window.open(
                                "https://renaissance.programmingpathshala.com/signup",
                                "_self"
                            )
                        }
                    ></div>
                    {props.isEventsPage ? (
                        <div onClick={() => scrollToRegForm()}>
                            <p className="reg-now-btn-mob">Register</p>
                        </div>
                    ) : (
                        <div onClick={() => (!loggedIn ? goToLoginPage() : initiateLogout())}>
                            {!loggedIn ? (
                                <p className="log-in-or-out-btn">Log In</p>
                            ) : (
                                <p className="log-in-or-out-btn">Log Out</p>
                            )}
                        </div>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default Header;
