import React from "react";
import "./styles/ActionButton.css";
const ActionButton = (props) => {
    return (
        <div className="action-button">
            <button variant="contained">{props.name}</button>
        </div>
    );
};

export default ActionButton;
