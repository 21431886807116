import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontSize: "13px",
    },
});
const style = {
    color: "white",
    background: "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
    fontFamily: "Poppins-SemiBold",
    textTransform: "capitalize",
    padding: "10px 16px",
    fontSize: "15px",
    whiteSpace: "noWrap",
};

const recordedButtonStyle = {
    color: "white",
    background: "linear-gradient(93.53deg, #444BAB 0%, #416BB3 62.5%, #3CA5C2 100%)",
    fontFamily: "Poppins-SemiBold",
    textTransform: "capitalize",
    padding: "10px 16px",
    fontSize: "15px",
    whiteSpace: "noWrap",
    width:100
}
const courseIncludesDropdownStyles = [
    {
        backgoundColor: "#F7FAFC",
    },
];

export { theme, style, courseIncludesDropdownStyles, recordedButtonStyle };
